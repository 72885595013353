import { browser } from '$app/environment';
import * as Sentry from '@sentry/sveltekit';

// Sentry.init({
// 	dsn: "https://31c1d252e6019e22feab7c0aab78ee34@o4507039439912960.ingest.us.sentry.io/4507039549882368",

// 	// We recommend adjusting this value in production, or using tracesSampler
// 	// for finer control
// 	tracesSampleRate: 1.0,

// 	// Optional: Initialize Session Replay:
// 	integrations: [Sentry.replayIntegration()],
// 	replaysSessionSampleRate: 0.1,
// 	replaysOnErrorSampleRate: 1.0,
// });

const myErrorHandler = ({ error, event }) => {
	console.error("An error occurred on the client side:", error, event);
};

export const handleError = Sentry.handleErrorWithSentry(myErrorHandler);

if (browser) {
	const originalFetch = window.fetch;
	window.fetch = async (input, init) => {
		if (typeof input === 'string') {
			input = input.replace('nginx:4000', 'localhost:4000');
		} else if (input instanceof Request) {
			input = new Request(input.url.replace('nginx:4000', 'localhost:4000'), init);
		}

		return originalFetch(input, init);
	};
}
