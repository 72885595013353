import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39')
];

export const server_loads = [2,3,4,5];

export const dictionary = {
		"/(app)": [~6,[2]],
		"/(app)/about": [10,[2]],
		"/(app)/auth/google": [12,[2,3]],
		"/(app)/auth/reset-password": [13,[2,3]],
		"/(app)/auth/sign-in": [~14,[2,3]],
		"/(app)/auth/sign-up": [~15,[2,3]],
		"/(app)/auth/yandex-auth": [16,[2,3]],
		"/(app)/auth/[uid]/[token]": [11,[2,3]],
		"/(app)/cart-pre-order": [22,[2]],
		"/(app)/cart-pre-order/cdec": [23,[2]],
		"/(app)/cart": [17,[2]],
		"/(app)/cart/cdec": [~18,[2]],
		"/(app)/cart/cdektest": [19,[2]],
		"/(app)/cart/gift-succes": [20,[2]],
		"/(app)/cart/succes": [~21,[2]],
		"/(app)/contacts": [24,[2]],
		"/(app)/delivery": [~25,[2]],
		"/(game)/game": [~33,[4]],
		"/(app)/gentlemen-products": [~26,[2]],
		"/(app)/gift-card": [27,[2]],
		"/(app)/oferts": [28,[2]],
		"/(app)/polytics": [29,[2]],
		"/(app)/pre-order-products": [~30,[2]],
		"/(app)/premium-products": [~31,[2]],
		"/(profile)/profile": [~34,[5]],
		"/(profile)/profile/change-password": [35,[5]],
		"/(profile)/profile/my-cachback": [~36,[5]],
		"/(profile)/profile/my-orders": [~37,[5]],
		"/(profile)/profile/my-orders/[id]": [~38,[5]],
		"/(profile)/profile/referal": [~39,[5]],
		"/(app)/sale": [~32,[2]],
		"/(app)/[brand]-b": [~7,[2]],
		"/(app)/[category]": [~8,[2]],
		"/(app)/[category]/[id=integer]": [~9,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';